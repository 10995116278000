body {
  font-family: "Roboto";
  margin: 0;
  padding: 0;
  height: 100%;
}

.link {
  color: white;
}

.Simul {
  font-family: sans-serif;
  text-align: center;
  justify-content: center;
  display: grid;
}

.App {
  font-family: sans-serif;
}
